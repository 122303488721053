import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

import { ViewerNavDataQuery } from '@/generated/graphql'

export const useAnalyticsIdentify = (
  user: ViewerNavDataQuery['viewer']['user'],
  currentOrganization: ViewerNavDataQuery['viewer']['currentOrganization'],
): void => {
  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.email,
        id: user.id,
        // eslint-disable-next-line camelcase
        ip_address: '{{auto}}',
        name: user.name,
        organizationSlug: currentOrganization?.slug,
      })
    }
  }, [user, currentOrganization])
}
