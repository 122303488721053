import clsx from 'clsx'
import { Camera } from 'react-feather'

const sizeClasses = {
  large: 'w-[48px] h-[48px] text-4xl',
  small: 'w-[32px] h-[32px] text-xl',
}

const OrgImage = ({
  active,
  orgName,
  orgLogo,
  size,
  editable,
}: {
  active?: boolean
  size: 'small' | 'large'
  orgName: string
  orgLogo?: string | null
  editable?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'border-divider-light-gray bg-interactive-secondary-hover group relative inline-flex select-none items-center justify-center rounded-md border bg-contain',
        sizeClasses[size],
        active && '!border-divider-light-blue border',
      )}
      style={orgLogo ? { backgroundImage: `url(${orgLogo})` } : {}}
    >
      <span className="text-copy-secondary">
        {!orgLogo ? orgName.charAt(0).toUpperCase() : null}
      </span>
      {editable ? (
        <div className="bg-background-buttonOverlay group-active:bg-background-activeButtonOverlay absolute top-0 bottom-0 left-0 right-0 hidden cursor-pointer items-center justify-center rounded-md group-hover:flex">
          <Camera color="white" width={16} />
        </div>
      ) : null}
    </div>
  )
}

export default OrgImage
