import * as Tooltip from '@radix-ui/react-tooltip'
import cx from 'clsx'
import * as React from 'react'

interface Props {
  readonly active?: boolean
  readonly align?: 'left' | 'right' | 'center'
  readonly className?: string
  readonly icon?: JSX.Element
  readonly size?: 'standard' | 'small' | 'tiny'
  readonly tooltip?: string | JSX.Element
  readonly tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left'
  readonly tooltipOffset?: readonly [number, number] // [alignOffset, sideOffset]
  readonly variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-dark'
    | 'ghost'
    | 'destructive'
    | 'upgrade'
    | 'upgrade-gradient'
    | 'generate-gradient'
}

export type ButtonProps = Props & React.ButtonHTMLAttributes<HTMLButtonElement>

const getActiveVariantClasses = (variant: Props['variant'] = 'primary'): readonly string[] => {
  switch (variant) {
    case 'primary':
    case 'upgrade':
    case 'upgrade-gradient': {
      return ['border', 'border-divider-dark-blue', 'bg-interactive-primary-active']
    }
    case 'generate-gradient': {
      return ['border', 'border-divider-dark-blue', 'bg-interactive-primary-active']
    }
    case 'secondary': {
      return [
        'border',
        '!border-divider-light-blue',
        '!bg-interactive-secondary-active',
        '!text-interactive-primary',
      ]
    }
    case 'secondary-dark': {
      return [
        'border',
        '!border-interactive-dark-active',
        '!bg-interactive-dark-active',
        '!text-interactive-primary',
      ]
    }
    case 'ghost': {
      return [
        'border',
        '!border-divider-light-blue',
        'bg-interactive-secondary-active',
        'text-interactive-primary',
      ]
    }
    case 'destructive': {
      return ['border', 'border-divider-dark-red', 'bg-interactive-destructive-active']
    }
  }
}

const getVariantClasses = (variant: Props['variant'] = 'primary'): readonly string[] => {
  switch (variant) {
    case 'primary': {
      return [
        'text-copy-active',
        'bg-interactive-primary',
        'shadow-button-primary',
        'border-transparent',
        'focus:border',
        'focus:bg-interactive-primary-hover',
        'focus:border-divider-dark-blue	',
        'enabled:hover:bg-interactive-primary-hover',
        'enabled:active:border',
        'aenabled:ctive:border-divider-dark-blue',
        'enabled:active:bg-interactive-primary-active',
        'enabled:active:shadow-none',
        'disabled:opacity-100',
        'disabled:border-transparent',
        'disabled:bg-divider-light-blue',
        'disabled:shadow-none',
      ]
    }
    case 'secondary': {
      return [
        'text-copy',
        'bg-interactive-secondary',
        'border-divider-light-gray',
        'shadow-button-secondary',
        'focus:border',
        'focus:border-divider-dark-gray',
        'enabled:hover:bg-interactive-secondary-hover',
        'enabled:active:border',
        'enabled:active:!border-divider-light-blue',
        'enabled:active:bg-interactive-secondary-active',
        'enabled:active:text-interactive-primary',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:shadow-none',
      ]
    }
    case 'secondary-dark': {
      return [
        'text-copy-active',
        'bg-interactive-dark',
        'border-transparent',
        'shadow-button-secondary-dark',
        'focus:border',
        'focus:border-divider-dark-blue',
        'enabled:hover:bg-interactive-dark-hover',
        'enabled:active:border',
        'enabled:active:!border-interactive-dark-active',
        'enabled:active:bg-interactive-dark-active',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:shadow-none',
      ]
    }
    case 'ghost': {
      return [
        'text-copy',
        'bg-none',
        'border-transparent',
        'focus:border',
        'enabled:hover:bg-interactive-secondary-hover',
        'enabled:active:border',
        'enabled:active:!border-divider-light-blue',
        'enabled:active:bg-interactive-secondary-active',
        'enabled:active:text-interactive-primary',
        'disabled:opacity-50',
      ]
    }
    case 'destructive': {
      return [
        'text-copy-active',
        'bg-interactive-destructive',
        'shadow-button-destructive',
        'border-transparent',
        'focus:border',
        'focus:border-divider-dark-red',
        'enabled:hover:bg-interactive-destructive-hover',
        'enabled:active:border',
        'enabled:active:border-divider-dark-red',
        'enabled:active:bg-interactive-destructive-active',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:shadow-none',
      ]
    }
    case 'upgrade': {
      return [
        'text-copy-active',
        'bg-base-highlightB',
        'shadow-button-primary',
        'border-transparent',
        'focus:border',
        'focus:bg-interactive-primary-hover',
        'focus:border-divider-dark-blue',
        'enabled:hover:bg-interactive-primary-hover',
        'enabled:active:border',
        'enabled:active:border-divider-dark-blue',
        'enabled:active:bg-interactive-primary-active',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:border-transparent',
        'disabled:shadow-none',
      ]
    }
    case 'upgrade-gradient': {
      return [
        'text-copy-active',
        'bg-gradient-to-r from-base-highlightB to-base-highlightC',
        'shadow-button-primary',
        'border-none',
        'focus:border',
        'focus:bg-interactive-primary-hover',
        'focus:border-divider-dark-blue',
        'enabled:hover:to-base-highlightB',
        'enabled:active:border',
        'enabled:active:border-divider-dark-blue',
        'enabled:active:bg-interactive-primary-active',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:border-none',
        'disabled:shadow-none',
      ]
    }
    case 'generate-gradient': {
      return [
        'text-copy-active',
        'bg-gradient-to-r from-base-highlightB to-base-highlightA',
        'shadow-button-primary',
        'border-none',
        'focus:border',
        'focus:bg-interactive-primary-hover',
        'focus:border-divider-dark-blue',
        'enabled:hover:to-base-highlightB',
        'enabled:active:border',
        'enabled:active:border-divider-dark-blue',
        'enabled:active:bg-interactive-primary-active',
        'enabled:active:shadow-none',
        'disabled:opacity-50',
        'disabled:border-none',
        'disabled:shadow-none',
      ]
    }
  }
}

const getSizeClasses = (size: Props['size'] = 'standard'): readonly string[] => {
  switch (size) {
    case 'small': {
      return ['!py-1 h-6']
    }
    case 'tiny': {
      return ['!p-0 h-full']
    }
    default: {
      return ['h-8']
    }
  }
}

const Button = (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
  const {
    active = false,
    align = 'center',
    icon,
    variant = 'primary',
    className,
    children,
    size = 'standard',
    tooltip,
    tooltipPlacement = 'top',
    tooltipOffset = [0, 0],
    ...rest
  } = props

  const ButtonComponent = (
    <button
      ref={ref}
      className={cx([
        // base
        'appearance-none',
        `inline-flex items-${align} justify-${align} items-center`,
        'p-2',
        'rounded',
        'font-semibold',
        'text-xs',
        'border',
        'focus:outline-none',
        'disabled:cursor-not-allowed',
        'diabled:opacity-50',
        'focus:border-divider-dark-blue',
        'focus:border',
        // dynamic
        ...getVariantClasses(variant),
        ...getSizeClasses(size),
        ...((active && getActiveVariantClasses(variant)) || []),
        // inherited
        className,
      ])}
      {...rest}
      style={rest.style ?? {}}
    >
      {icon}
      {children}
    </button>
  )

  if (!tooltip) return ButtonComponent

  // Note: Disabled buttons don't fire events so we need to wrap it in a span to
  // show tooltip.
  const child = rest.disabled ? <span>{ButtonComponent}</span> : ButtonComponent

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={150}>
        <Tooltip.Trigger asChild>{child}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side={tooltipPlacement}
            alignOffset={tooltipOffset[0]}
            sideOffset={tooltipOffset[1]}
            className="z-max bg-background-dark text-copy-active shadow-dark-hard max-w-[200px] rounded p-2 text-center text-xs font-normal"
          >
            {tooltip}
            <Tooltip.Arrow className="fill-background-dark" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button)
